require('./bootstrap');

(function ($) {
    "use strict";

    /* FOR DESKTOP ONLY */
    if (window.matchMedia("(min-width: 992px)").matches) {

        /* DISPLAY BS4 TOOLTIP */
        $('[data-toggle="tooltip"]').tooltip();

        /* GO TOP */
        var btn = $('#cotiTop');
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 300) {
                btn.show(10).animate({
                    right: '8px'
                }, 0);
            } else {
                btn.animate({
                    right: '-48px'
                }, 0);
            }
        });
    };

})(jQuery);
